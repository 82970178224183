import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const ContactPage = () => (
    <Layout>
        <SEO title="Contact Confirmation" />
        <section className="hero is-primary is-fullheight-with-navbar">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <h2 className="subtitle">Thanks, we&apos;ll be in touch soon!</h2>
                </div>
            </div>
        </section>
    </Layout>
);

export default ContactPage;
